import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import Video from '../assets/videos/event_galerie_2023.mp4'

const Bio = () => {
  return (
    <Evenenements>

    <ContainerFlex>
        <Event>
            <Image1>
            <Custom>
                <h5>Exposition Lesalonreçoit </h5>
                <h5>.....</h5>
            </Custom>
                <HeroBg>
                   <VideoBg src={Video} type="video/mp4" autoPlay loop muted playsInline />
                </HeroBg>
            </Image1>

            <Image2>
            <Custom>
                <h5>Exposition Place aux arts </h5>
                <h5>.....</h5>
            </Custom>
                <StaticImage
                src="../assets/images/galerie_2023.jpeg"
                width={300}
                alt="Bio"
                placeholder="traceBLURREDdSVG"
                quality={40}
                />
        </Image2>
        </Event>
        </ContainerFlex>
    </Evenenements>
  )
}

export default Bio

const Evenenements = styled.div`

  `
  const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`
  const HeroBg = styled.div`
  width: 50%;
  height: 50%;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`
  const ContainerFlex = styled.div`
  margin: 0rem ;
  color: rgba(169, 73, 69, 0.636);
  background-color: #404640;  
      @media screen and (max-width: 768px) {
        margin: 0rem ;
        margin-top: 0rem ;
        background-color: white;  
  `
const Image1 = styled.div`
  padding: 3rem;
  padding-left: 0rem;
  text-align: left;
  heigth: auto;
      @media screen and (max-width: 768px) {
        padding: 0rem;
        margin: 0rem;
        padding-bottom: 2rem;
         text-align: center;
      }
`
const Image2 = styled.div`
  padding: 3rem;
  padding-right: 0rem;
  text-align: right;
  heigth: auto;
      @media screen and (max-width: 768px) {
        margin: 0rem;
        padding: 0rem;
        text-align: center;
        padding-bottom: 2rem;
        padding-top: 2rem;
      }
`
const Event = styled.div`
margin-left: 15rem;
margin-right: 15rem;
  @media screen and (max-width: 768px) {
    margin-left: 0rem;
    margin-right: 0rem;
    }
    li {
      font-size: 0,5rem;
      font-weight: bold;
      color: #ab9668;
      text-align:left;
      }
      @media screen and (max-width: 768px) {
        padding: 1rem;
        li {
          margin-left: 1rem;
          margin-top: 1rem;
          }
            }
          
`
const Custom = styled.p`
h5 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ab9668;
  @media screen and (max-width: 768px) {

  }
  `