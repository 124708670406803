import * as React from "react"
import Layout from "../components/layout"
import Event1 from "../components/Event-1"

const Event1Page = () => (
  <Layout>
    <Event1 />
  </Layout>
)

export default Event1Page